import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { db } from "../../utils/firebase";
import { translate } from "../../utils/i18n";
import useStyles from "./styles";

const Credits = () => {
  const classes = useStyles();
  const [contributors, setContributors] = useState<Record<string, string>[]>([]);

  // @ts-ignore
  useEffect(() => {
    db.collection("neoartworks").onSnapshot((querySnapshot) => {
      const tempContributorsArray = [];
      querySnapshot.forEach((doc) => {
        // @ts-ignore
        tempContributorsArray.push({ name: doc.get("author"), id: doc.get("id") });
      });
      setContributors(tempContributorsArray);
    });
  }, []);

  return (
    <div className={classes.revertPadding}>
      <div className={classes.contentSpacer}>
        <Typography variant="h6" component="p">
          {translate("common.artist")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {translate("credits.artistContent")}
        </Typography>

        <Typography variant="h6" component="p">
          {translate("credits.financedBy")}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <img style={{ width: "100%" }} src="../../assets/images/calq.png" alt="" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <img style={{ width: "100%", maxWidth: 185 }} src="../../assets/images/matane.jpeg" alt="" />
          </Grid>
        </Grid>

        <Typography variant="h6" component="p">
          {translate("credits.theJubilee")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {translate("credits.theJubileeContent")}
        </Typography>

        <Typography variant="h6" component="p">
          {translate("credits.PVPsubsidiary")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {translate("credits.producer")}
          <br />
          {translate("credits.producerContent")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {translate("credits.directorMedias")}
          <br />
          {translate("credits.directorMediasContent")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {translate("credits.directorArts")}
          <br />
          {translate("credits.directorArtsContent")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {translate("credits.directorDev")}
          <br />
          {translate("credits.directorDevContent")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {translate("credits.directorWritings")}
          <br />
          {translate("credits.directorWritingsContent")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {translate("credits.writing")}
          <br />
          {translate("credits.writingContent")}
        </Typography>

        <Typography variant="h6" component="p">
          {translate("credits.coCreators")}
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
          {contributors.map((contributor) => (
            <Box component="span" key={contributor.id}>
              {contributor.name}
              <br />
            </Box>
          ))}
        </Typography>
      </div>
    </div>
  );
};

export default Credits;
