import React, { useState, useEffect, useRef, RefObject } from "react";

import WordPositioning from "../WordPositioning";
import useStyles from "./styles";

const ArtworkWithWords = ({ readonly, neoArtwork }: { readonly?: boolean; neoArtwork: any }) => {
  const neoArtworkWrapperRef: RefObject<HTMLElement> = useRef(null);
  const imgRef: RefObject<HTMLImageElement> = useRef(null);

  const classes = useStyles();

  const [scale, setScale] = useState(1);

  useEffect(() => {
    const img = imgRef.current;

    window.addEventListener("resize", onResize);
    img?.addEventListener("load", onResize);

    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
      img?.removeEventListener("load", onResize);
    };
  });

  const onResize = () => {
    const img = imgRef.current;
    const viewFrame = neoArtworkWrapperRef.current;

    if (img && viewFrame) {
      const { clientWidth, clientHeight } = viewFrame;
      const w = img.naturalWidth;
      const h = img.naturalHeight;

      const rw = clientWidth / w;
      const rh = clientHeight / h;

      if (rw > 0 && rh > 0) {
        if (rw > rh) {
          setScale(rh);
        } else {
          setScale(rw);
        }
      }
    }
  };

  return (
    <div className={classes.artworkDisplayZone} ref={neoArtworkWrapperRef as React.RefObject<HTMLDivElement>}>
      <div
        className={classes.artworkImageWrapper}
        style={{
          transform: `scale(${scale}, ${scale})`
        }}
      >
        {Boolean(neoArtwork?.artwork?.url) && (
          <img
            ref={imgRef as React.RefObject<HTMLImageElement>}
            className={classes.artworkImage}
            src={neoArtwork?.artwork?.url}
            alt={neoArtwork.artwork.name}
          />
        )}

        {Boolean(neoArtwork?.wording) && (
          <WordPositioning scale={scale} readonly={readonly} wording={neoArtwork.wording} />
        )}
      </div>
    </div>
  );
};

export default ArtworkWithWords;
