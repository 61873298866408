import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import MainLayout from "../components/Layouts/MainLayout";
import Home from "../views/Home";
import Slideshow from "../views/Slideshow";
import CreateNeoArtwork from "../views/CreateNeoArtwork";
import Terms from "../views/Terms";
import About from "../views/About";
import Credits from "../views/Credits";
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import Upload from "../views/Upload";
import ViewNeoArtwork from "../views/ViewNeoArtwork";
import { PrivateRoute, RestrictedRoute } from ".";
import { AuthProvider } from "../contexts/AuthContext";
import { RestrictedAccessProvider } from "../contexts/RestrictedContext";
import { analytics } from "../utils/firebase";

const renderWithMainLayout = (Component: React.ComponentType<any>) => (props: any) =>
  (
    <MainLayout {...props}>
      <Component {...props} />
    </MainLayout>
  );

const Routes = () => {
  const history = useHistory();

  useEffect(() => {
    trackPageView();
    history.listen(trackPageView);
  }, [history]);

  function trackPageView() {
    analytics.logEvent("pageview", { page: window.location.pathname });
  }

  return (
    <AuthProvider>
      <RestrictedAccessProvider>
        <Switch>
          <Route path="/" exact strict component={renderWithMainLayout(Home)} />
          <Route path="/diaporama" exact strict component={renderWithMainLayout(Slideshow)} />
          <Route path="/diaporama/:id" exact strict component={renderWithMainLayout(Slideshow)} />
          <Route path="/visionner/:id" exact component={renderWithMainLayout(ViewNeoArtwork)} />
          <Route path="/termes" exact component={renderWithMainLayout(Terms)} />
          <Route path="/credits" exact component={renderWithMainLayout(Credits)} />
          <Route path="/a-propos" exact component={renderWithMainLayout(About)} />
          <Route path="/login" exact component={renderWithMainLayout(Login)} />
          <RestrictedRoute path="/creer" exact component={renderWithMainLayout(CreateNeoArtwork)} />
          <PrivateRoute path="/dashboard" exact component={renderWithMainLayout(Dashboard)} />
          <PrivateRoute path="/upload" exact component={renderWithMainLayout(Upload)} />
        </Switch>
      </RestrictedAccessProvider>
    </AuthProvider>
  );
};

export default Routes;
