import { makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  revertPadding: {
    backgroundColor: theme.palette.neutral.main,
    margin: theme.spacing(-5),
    minHeight: `calc(100vh - ${theme.spacing(11)}px)`
  },
  contentSpacer: {
    color: "white",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 5)
    },
    minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
    textAlign: "center"
  },
  infoBox: {
    position: "absolute",
    bottom: theme.spacing(4),
    left: theme.spacing(4)
  },
  infoBoxText: {
    textAlign: "left",
    textShadow: "1px 1px 1px black"
  }
}));

export default useStyles;
