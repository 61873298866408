import React, { useState, ChangeEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useAuth } from "../../contexts/AuthContext";
import { translate } from "../../utils/i18n";
import useStyles from "./styles";

import { LoginState } from "./index.interface";

const Login = () => {
  const classes = useStyles();
  // @ts-ignore
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/dashboard" } };
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [formData, setFormData] = useState<LoginState>({
    values: {
      email: "",
      password: ""
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange = (prop: keyof LoginState["values"]) => (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormData((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [prop]: event.target.value
      }
    }));
  };

  async function onSubmitHandler(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(formData.values.email, formData.values.password);
      setLoading(false);
      history.replace(from);
    } catch (err) {
      setError("Failed to log in");
      setLoading(false);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmitHandler}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            data-testid="login-email-field"
            label={translate("common.email")}
            name="email"
            onChange={handleChange("email")}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={translate("common.password")}
            type={showPassword ? "text" : "password"}
            id="password"
            onChange={handleChange("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Button variant="contained" type="submit">
            Log in
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default Login;
