// Temporary commenting eslint rule `import/no-extraneous-dependencies` due to current known bug
// follow here: https://github.com/benmosher/eslint-plugin-import/issues/2065

// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from "firebase/app";
// eslint-disable-next-line import/no-extraneous-dependencies
import "firebase/auth";
// eslint-disable-next-line import/no-extraneous-dependencies
import "firebase/firestore";
// eslint-disable-next-line import/no-extraneous-dependencies
import "firebase/storage";
// eslint-disable-next-line import/no-extraneous-dependencies
import "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = firebase.initializeApp({
  apiKey: "AIzaSyByWvlLu5BOtYQYs0-4eubfF3lnMssdjLM",
  authDomain: "emiesirois-8cb1a.firebaseapp.com",
  databaseURL: "https://emiesirois-8cb1a-default-rtdb.firebaseio.com",
  projectId: "emiesirois-8cb1a",
  storageBucket: "emiesirois-8cb1a.appspot.com",
  messagingSenderId: "516689260156",
  appId: "1:516689260156:web:185323add241ab0a6de3f9",
  measurementId: "G-DXQRGN5D0C"
});

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const analytics = app.analytics();
export default app;
