import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SaveIcon from "@material-ui/icons/Save";

import ImageMasonry from "../../../../components/ImageMasonry";
import ArtworkWithWords from "../../../../components/ArtworkWithWords";
import PublishDialog, { authorNameState, artworkNameState } from "../../../../components/PublishDialog";
import ImageMasonryListItem from "../../../../components/ImageMasonry/ImageMasonryListItem";
import { neoArtworkState } from "../SelectArtwork";
import { db } from "../../../../utils/firebase";
import { translate } from "../../../../utils/i18n";
import useStyles from "./styles";

import { Word } from "../../../../types";

export const selectedWordListState = atom({
  key: "selectedWordList",
  default: []
});

export const wordListIsEditableState = atom({
  key: "wordListIsEditable",
  default: true
});

const SelectWords = () => {
  const classes = useStyles();
  const [wordList, setWordList] = useState<Word[]>([]);
  const [selectedWordList, setSelectedWordList] = useRecoilState(selectedWordListState);
  const setWordListIsEditable = useSetRecoilState(wordListIsEditableState);
  const [isWordDrawerOpen, setIsWordDrawerOpen] = useState(false);
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [neoArtwork] = useRecoilState(neoArtworkState);
  const neoAuthorName = useRecoilValue(authorNameState);
  const neoArtworkName = useRecoilValue(artworkNameState);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    setSelectedWordList(() => []);
    setWordListIsEditable(true);

    const unmount = db.collection("words").onSnapshot((snapshot) => {
      const tempWords = [];
      snapshot.forEach((doc) => {
        // @ts-ignore
        tempWords.push({ ...doc.data() });
      });
      setWordList(tempWords);
    });
    return unmount;
  }, [setWordListIsEditable, setSelectedWordList]);

  const handleOnAddWordActionClick = () => {
    setIsWordDrawerOpen(true);
    setIsSpeedDialOpen(false);
  };

  const handleOnPublishActionClick = () => {
    setIsPublishModalOpen(true);
    setIsSpeedDialOpen(false);
    setWordListIsEditable(false);
  };

  const handleOnCancelPublishAction = () => {
    setIsPublishModalOpen(false);
    setIsSpeedDialOpen(false);
    setWordListIsEditable(true);
  };

  const handleOnWordClick = (word) => {
    handleAddWordToList(word);
    setIsWordDrawerOpen(false);
    setIsSpeedDialOpen(false);
  };

  const handleAddWordToList = (word) => {
    // @ts-ignore
    setSelectedWordList((oldWordList) => [
      ...oldWordList,
      { ...word, id: uuid(), position: { top: 0, left: 0 }, size: { width: word.width, height: word.height } }
    ]);
  };

  const handleSubmitNeoArtwork = async () => {
    await db
      .collection("neoartworks")
      .doc(neoArtwork.id)
      .set({
        id: neoArtwork.id,
        author: neoAuthorName,
        name: neoArtworkName,
        submittedAt: new Date(),
        artwork: {
          ...neoArtwork.artwork.artwork,
          artist: neoArtwork.artwork.artist,
          gallery: neoArtwork.artwork.gallery
        },
        wording: selectedWordList.map((word: Word) => word),
        shareableUrl: ""
      });
    setIsRequestLoading(false);
    enqueueSnackbar(translate("create.publishSuccessful"));
    history.push(`/visionner/${neoArtwork.id}`);
  };

  return (
    <div className={classes.tabpanelRootOverride}>
      <ArtworkWithWords neoArtwork={{ ...neoArtwork.artwork, wording: selectedWordList }} readonly={false} />

      {isRequestLoading && <LinearProgress color="secondary" className={classes.loader} />}

      <SpeedDial
        ariaLabel="edit artwork actions"
        className={classes.fab}
        icon={<SpeedDialIcon />}
        onClick={() => setIsSpeedDialOpen(true)}
        onClose={() => setIsSpeedDialOpen(false)}
        open={isSpeedDialOpen}
      >
        <SpeedDialAction
          icon={<SaveIcon />}
          tooltipTitle={translate("common.publish")}
          tooltipOpen
          onClick={handleOnPublishActionClick}
        />
        <SpeedDialAction
          icon={<PostAddIcon />}
          tooltipTitle={translate("create.addWord")}
          tooltipOpen
          onClick={handleOnAddWordActionClick}
        />
      </SpeedDial>

      <Drawer anchor="bottom" open={isWordDrawerOpen} onClose={() => setIsWordDrawerOpen(false)}>
        <Box className={classes.imageListWrapper}>
          <ImageMasonry cols={3} gap={8}>
            {wordList.map((word) => (
              <ImageMasonryListItem key={word.id} onClick={() => handleOnWordClick(word)}>
                <img src={word.url} alt={word.name} width={word.width} height={word.height} />
              </ImageMasonryListItem>
            ))}
          </ImageMasonry>
        </Box>
      </Drawer>

      <PublishDialog
        isOpen={isPublishModalOpen}
        onCloseHandler={handleOnCancelPublishAction}
        onSubmitHandler={handleSubmitNeoArtwork}
      />
    </div>
  );
};

export default SelectWords;
