import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";

import SelectArtwork from "./subviews/SelectArtwork";
import SelectWords from "./subviews/SelectWords";
import { db } from "../../utils/firebase";
import useStyles from "./styles";

import { Artwork } from "../../types";

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const CreateNeoArtwork = () => {
  const classes = useStyles();
  const [artworks, setArtworks] = useState<Artwork[]>([]);
  const [selectedArtwork, setSelectedArtwork] = useState<Artwork | null>(null);
  const [currentSubviewIndex, setCurrentSubviewIndex] = useState(0);

  useEffect(() => {
    const unmount = db.collection("artworks").onSnapshot((snapshot) => {
      const tempArtworks = [];
      snapshot.forEach((doc) => {
        // @ts-ignore
        tempArtworks.push({ ...doc.data() });
      });

      setArtworks(tempArtworks);
      setSelectedArtwork(tempArtworks[0]);
    });
    return unmount;
  }, []);

  const handleOnSelectArtwork = (artwork: Artwork) => {
    if (artwork.artwork.url) {
      setSelectedArtwork(artwork);
      handleOnTriggerSelectArtwork();
    }
  };

  const handleOnTriggerSelectArtwork = () => {
    setCurrentSubviewIndex(1);
  };

  return (
    <div className={classes.revertPadding}>
      <div className={classes.contentSpacer}>
        <TabPanel value={currentSubviewIndex} index={0}>
          <SelectArtwork artworks={artworks} onSelectArtworkHandler={handleOnSelectArtwork} />
        </TabPanel>
        <TabPanel value={currentSubviewIndex} index={1}>
          <SelectWords />
        </TabPanel>
      </div>
    </div>
  );
};

export default CreateNeoArtwork;
