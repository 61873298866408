import React from "react";
import Draggable from "react-draggable";

import { WordStyle } from "../../types";

type DragProps = {
  position: WordStyle["position"];
  scale: number;
  onDrag?: (position: WordStyle["position"]) => void;
  onStop?: (position: WordStyle["position"]) => void;
  disabled: boolean;
};

const DraggableBox: React.FC<DragProps> = ({ scale, position, onDrag, onStop, children, disabled }) => {
  return (
    <Draggable
      cancel="button"
      scale={scale}
      disabled={disabled}
      position={{ x: position.left, y: position.top }}
      onStop={(e: any, data) => {
        if (onStop) {
          onStop({
            left: data.lastX,
            top: data.lastY
          });
        }
      }}
    >
      {children}
    </Draggable>
  );
};

export default DraggableBox;
