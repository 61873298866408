import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  revertPadding: {
    margin: theme.spacing(-5)
  },
  tabpanelWrapper: {
    padding: theme.spacing(2)
  },
  tabpanel: {
    paddingTop: theme.spacing(2)
  }
}));

export default useStyles;
