import { createStyles } from "@material-ui/styles";
import { colors } from "@material-ui/core";

const styles = (theme) =>
  createStyles({
    dropZone: {
      border: `1px dashed ${theme.palette.divider}`,
      padding: theme.spacing(6),
      outline: "none",
      display: "flex",
      flexGrow: 1,
      height: "inherit",
      width: "inherit",
      justifyContent: "center",
      flexWrap: "wrap",
      alignItems: "center"
    },
    dragActive: {
      backgroundColor: colors.grey[50],
      opacity: 0.5
    },
    errorMsg: {
      color: colors.red[900]
    }
  });

export default styles;
