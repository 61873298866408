import React, { forwardRef, useMemo } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useRestrictedAccess } from "../../contexts/RestrictedContext";

import { translate } from "../../utils/i18n";
import { navItemsById } from "../../navigation/navigationConfig";
import useStyles from "./styles";

function NavButton(props) {
  const { color, to, children } = props;
  const { search } = useLocation();
  const renderLink = useMemo(
    () => forwardRef((itemProps, ref) => <RouterLink exact to={to + search} ref={ref} {...itemProps} />),
    [to, search]
  );

  return (
    <Button component={renderLink} color={color}>
      {children}
    </Button>
  );
}

const Home = () => {
  const classes = useStyles();
  // @ts-ignore
  const { accessGranted } = useRestrictedAccess();

  return (
    <div className={classes.revertPadding}>
      <div className={classes.contentSpacer}>
        <Typography variant="h5" component="h1" gutterBottom>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: translate("homepage.title", { interpolation: { escapeValue: false } })
            }}
          />
        </Typography>

        <Typography variant="body2" component="p" gutterBottom className={classes.paragraph}>
          {translate("homepage.p1")}
        </Typography>

        <NavButton to={navItemsById.slideshow.href} color="primary">
          {translate("homepage.action1")}
        </NavButton>
        {accessGranted && (
          <>
            <Typography variant="body2" component="p" gutterBottom className={classes.paragraph}>
              {translate("homepage.p2")}
            </Typography>

            <NavButton to={navItemsById.create.href} color="primary">
              {translate("homepage.action2")}
            </NavButton>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
