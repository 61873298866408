import React, { ReactNode } from "react";

import useStyles from "./styles";

export const ImageListContext = React.createContext({});

const ImageList = React.forwardRef((inProps: { children: ReactNode; cols: number; gap: number }, ref) => {
  const classes = useStyles();

  const { children, cols = 2, gap = 4, ...other } = inProps;

  const contextValue = React.useMemo(() => ({ rowHeight: "auto", gap }), [gap]);

  return (
    <ul
      className={classes.root}
      // @ts-ignore
      ref={ref}
      {...other}
    >
      <ImageListContext.Provider value={contextValue}>{children}</ImageListContext.Provider>
    </ul>
  );
});

export default ImageList;
