import React from "react";
import SwipeableViews from "react-swipeable-views";
import { virtualize, bindKeyboard, autoPlay } from "react-swipeable-views-utils";

import ArtworkWithWords from "../../../../components/ArtworkWithWords";
import useStyles from "./styles";

const VirtualizeSwipeableViews = autoPlay(bindKeyboard(virtualize(SwipeableViews)));

const SlideItem = ({ neoArtwork }) => {
  const classes = useStyles();

  return (
    <div className={classes.artworkImageWrapper}>
      <ArtworkWithWords readonly neoArtwork={neoArtwork} />
    </div>
  );
};

const NeoArtworkCarousel = ({ artworks, currentIndex, onChangeIndexHandler }) => {
  const slideCount = artworks.length;
  const classes = useStyles();

  if (!artworks.length) {
    return null;
  }

  const slideRenderer = (params) => {
    const { index } = params;

    return <SlideItem key={`${artworks[index].id}-${index}`} neoArtwork={artworks[index]} />;
  };

  const handleChangeIndex = (index: number) => {
    if (onChangeIndexHandler) {
      onChangeIndexHandler(index);
    }
  };

  return (
    <VirtualizeSwipeableViews
      className={classes.container}
      onChangeIndex={handleChangeIndex}
      slideCount={slideCount}
      slideRenderer={slideRenderer}
      index={currentIndex}
      interval={7500}
      animateTransitions={false}
      overscanSlideBefore={slideCount}
    />
  );
};

export default NeoArtworkCarousel;
