import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "block",
    overflowY: "auto",
    listStyle: "none",
    padding: 0,
    // Add iOS momentum scrolling for iOS < 13.0
    WebkitOverflowScrolling: "touch"
  },
  listItem: {
    display: "inline-block",
    position: "relative",
    verticalAlign: "middle",
    margin: theme.spacing(1),
    lineHeight: 0, // 🤷🏻‍♂️Fixes masonry item gap
    [`& > img`]: {
      objectFit: "cover",
      width: "100%",
      height: "100%"
    }
  }
}));

export default useStyles;
