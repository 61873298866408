import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: 540
  },
  fileUploadWrapper: {
    position: "relative",
    width: 325,
    height: 255,
    margin: theme.spacing(2, 0, 1)
  },
  artworkPreview: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    objectFit: "contain"
  },
  fileUploadContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%"
  },
  submit: {
    marginTop: theme.spacing(3)
  }
}));

export default useStyles;
