import React, { ChangeEvent } from "react";
import { atom, useRecoilState } from "recoil";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";

import { translate } from "../../utils/i18n";
import useStyles from "./styles";

export const authorNameState = atom({
  key: "authorName",
  default: ""
});
export const artworkNameState = atom({
  key: "artworkName",
  default: ""
});

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children?: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const PublishDialog = ({ isOpen, onCloseHandler, onSubmitHandler }) => {
  const classes = useStyles();
  const [authorName, setAuthorName] = useRecoilState(authorNameState);
  const [artworkName, setArtworkName] = useRecoilState(artworkNameState);
  const theme = useTheme();
  const isDialogFullscreen = useMediaQuery(theme.breakpoints.only("xs"));

  const handleChange = (prop: "authorName" | "artworkName") => (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (prop === "authorName") {
      setAuthorName(() => event.target.value);
    }

    if (prop === "artworkName") {
      setArtworkName(() => event.target.value);
    }
  };

  const handleClose = () => {
    if (onCloseHandler) {
      onCloseHandler();
    }
  };

  const handleSubmit = (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    if (onSubmitHandler) {
      onSubmitHandler();
      onCloseHandler();
      setAuthorName(() => "");
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="publish-dialog-title"
        PaperProps={{ component: "form", onSubmit: handleSubmit }}
        TransitionComponent={Transition}
        fullScreen={isDialogFullscreen}
      >
        <DialogTitle id="publish-dialog-title">{translate("create.publishModalTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{translate("create.publishModalDescription")}</DialogContentText>
          <TextField
            id="authorName"
            className={classes.textfield}
            label={translate("common.fullname")}
            value={authorName}
            onChange={handleChange("authorName")}
            inputProps={{ minLength: 4 }}
            fullWidth
            autoFocus
          />
          <TextField
            id="artworkName"
            className={classes.textfield}
            label={translate("common.artworkName")}
            value={artworkName}
            onChange={handleChange("artworkName")}
            inputProps={{ minLength: 4 }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate("common.cancel")}</Button>
          <Button disabled={authorName.length < 4} onClick={handleSubmit} color="primary">
            {translate("common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PublishDialog;
