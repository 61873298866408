import React from "react";
import { Resizable, ResizeHandle as ReactResizeHandle } from "react-resizable";

import { WordStyle } from "../../types";

import "./handles.css";

const handlePlacements: ReactResizeHandle[] = ["se"];

type ResizeProps = {
  onResize: (style: WordStyle) => void;
  disabled: boolean;
} & WordStyle;

const ResizableBox: React.FC<ResizeProps> = ({ children, position, size, onResize, disabled }) => {
  return (
    <Resizable
      width={size.width}
      height={size.height}
      onResizeStart={(e) => e.stopPropagation()}
      onResize={(_, { size: newSize, handle }) => {
        let topDiff = 0;
        if (handle.includes("n")) {
          topDiff = size.height - newSize.height;
        }

        let leftDiff = 0;
        if (handle.includes("w")) {
          leftDiff = size.width - newSize.width;
        }

        if (!disabled) {
          onResize({
            size: {
              width: Math.round(newSize.width),
              height: Math.round(newSize.height)
            },
            position: {
              top: position.top + topDiff,
              left: position.left + leftDiff
            }
          });
        }
      }}
      resizeHandles={!disabled ? handlePlacements : []}
      axis="x"
      lockAspectRatio
    >
      <div
        style={{
          width: size.width,
          height: size.height,
          position: "relative"
        }}
      >
        {children}
      </div>
    </Resizable>
  );
};

export default ResizableBox;
