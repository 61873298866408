import React, { useState } from "react";
import clsx from "clsx";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";

import { translate } from "../../utils/i18n";
import styles from "./styles";

const FileDropzone = (props) => {
  const { classes, acceptMultiple = false } = props;
  const [errors, setErrors] = useState<string[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: acceptMultiple,
    accept: "image/jpg, image/jpeg, image/png",
    maxSize: 1024 * 1024 * 10,
    onDrop: (acceptedFiles) => {
      setErrors([]);
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        // eslint-disable-next-line no-console
        reader.onabort = () => console.log("file reading was aborted");
        // eslint-disable-next-line no-console
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          props.onFilesDropHandler(file);
        };
      });
    },
    onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => {
      const errs: string[] = [];
      fileRejections.forEach((rf: FileRejection) => {
        const msg = translate(
          rf.errors[0].code === "file-too-large" ? "notifications.fileSize" : "notifications.fileInvalid"
        );
        errs.push(msg.replace("{file}", rf.file.name));
        // @ts-ignore
        props.onFilesDropHandler({ name: rf.file.name, size: rf.file.size, content: "" });
      });
      setErrors(errs);
    }
  });

  return (
    <div
      className={clsx({
        [classes.dropZone]: true,
        [classes.dragActive]: isDragActive
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Button className={classes.uploadBtn} size="large" startIcon={<PublishIcon />} component="span">
        {translate("common.attachFiles")}
      </Button>
      {errors.length > 0 &&
        errors.map((err: string) => {
          return (
            <p key={err} className={classes.errorMsg}>
              {err}
            </p>
          );
        })}
    </div>
  );
};

export default withStyles(styles)(FileDropzone);
