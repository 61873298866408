import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflowY: "auto",
    minHeight: `calc(100vh - ${theme.spacing(11)}px)`,
    paddingTop: 88,
    [theme.breakpoints.up("md")]: {
      paddingTop: 0
    }
  },
  navbarWrapper: {
    flexShrink: 0,
    boxShadow: theme.shadows[1],
    zIndex: theme.zIndex.appBar - 1
  },
  navbar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: "0 0 auto"
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    margin: `${theme.spacing(0)} auto`,
    padding: theme.spacing(5),
    position: "relative"
  }
}));

export default useStyles;
