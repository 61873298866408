import i18next, { TFunctionKeys, TOptions } from "i18next";

/**
 * Translates text by key
 * https://www.i18next.com/translation-function/essentials
 *
 * @param key The i18n key.
 * @param opts
 */
export function translate(key: TFunctionKeys, opts?: TOptions): string {
  return key ? i18next.t(key, opts) : "";
}

/**
 * Translates text by key with interpolation
 * https://www.i18next.com/translation-function/interpolation
 *
 * @param key The i18n key
 * @param vars Additional placeholder/value pair(s) to replace.
 */
export function translateInterpolation(key: string, vars: Record<string, unknown>) {
  return key ? i18next.t(key, vars) : null;
}
