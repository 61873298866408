import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  deleteButtonWrapper: {
    position: "absolute",
    top: -50,
    right: -25,
    zIndex: 100
  },
  deleteButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#f44336"
    }
  }
}));

export default useStyles;
