import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  revertPadding: {
    backgroundColor: theme.palette.neutral.main,
    margin: theme.spacing(-5),
    minHeight: `calc(100vh - ${theme.spacing(11)}px)`
  },
  contentSpacer: {
    color: "white",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 5)
    },
    height: "100%",
    maxHeight: `calc(100vh - ${theme.spacing(11)}px)`,
    textAlign: "center"
  },
  artworkImageWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  infoBox: {
    position: "absolute",
    bottom: theme.spacing(4),
    left: theme.spacing(4)
  },
  infoBoxText: {
    textAlign: "left",
    textShadow: "1px 1px 1px black"
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  wordingWrapper: {
    position: "absolute",
    zIndex: 0
  }
}));

export default useStyles;
