import React from "react";
import Typography from "@material-ui/core/Typography";

import { translate } from "../../utils/i18n";
import useStyles from "./styles";

const Terms = () => {
  const classes = useStyles();

  return (
    <div className={classes.revertPadding}>
      <div className={classes.contentSpacer}>
        <Typography variant="h5" component="h1" gutterBottom>
          {translate("terms.title")}
        </Typography>

        <Typography variant="body2" component="p" gutterBottom className={classes.paragraph}>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: translate("legals.terms", { interpolation: { escapeValue: false } })
            }}
          />
        </Typography>
      </div>
    </div>
  );
};

export default Terms;
