import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  artworkImageWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    height: `calc(100vh - ${theme.spacing(11)}px)`,
    overflow: "hidden"
  },
  artworkImage: {
    width: "auto",
    maxHeight: `calc(100vh - ${theme.spacing(11)}px)`
  },
  controlsWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  prevButton: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    "&:disabled": {
      backgroundColor: "transparent",
      color: "transparent"
    }
  },
  nextButton: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    "&:disabled": {
      backgroundColor: "transparent",
      color: "transparent"
    }
  },
  container: {
    //    width: "100%",
    //    position: "absolute"
  }
}));

export default useStyles;
