import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";

import Header from "../../Header";
import Navbar from "../../Navbar";
import navigationConfig from "../../../navigation/navigationConfig";
import useStyles from "./styles";

import { MainLayoutProps } from "./index.interface";

function MainLayout({ children }: MainLayoutProps) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const filteredRoutes = navigationConfig.filter((route) => !route.limited);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <Header toggleDrawerHandler={handleDrawerToggle} />
      <div className={classes.container}>
        <div className={classes.navbarWrapper}>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
          >
            <Navbar pages={filteredRoutes} />
          </Drawer>
        </div>

        <main className={classes.content}>{children}</main>
      </div>
    </div>
  );
}

export default MainLayout;
