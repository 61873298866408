import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  artworkDisplayZone: {
    position: "absolute",
    display: "block",
    height: `100%`,
    width: "100%",
    minHeight: "350px",
    minWidth: "350px",
    overflow: "hidden"
  },
  artworkImageWrapper: {
    position: "absolute",
    left: "50%",
    top: "50%"
  },
  artworkImage: {
    position: "absolute",
    transform: "translate(-50%, -50%)"
  }
}));

export default useStyles;
