import * as React from "react";
import { withRouter, Route, Redirect, RouterChildContext } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

interface PrivateRouteProps extends RouterChildContext {
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  // @ts-ignore
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        // @ts-ignore
        currentUser && currentUser.uid ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
