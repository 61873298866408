import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  tabpanelRootOverride: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "transparent",
    overflow: "hidden"
  },
  artworkDisplayZone: {
    position: "absolute",
    display: "block",
    height: `100%`,
    width: "100%",
    overflow: "hidden"
  },
  artworkImageWrapper: {
    position: "absolute",
    left: "50%",
    top: "50%"
  },
  artworkImage: {
    position: "absolute",
    transform: "translate(-50%, -50%)"
  },
  gridListWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "inherit",
    height: 450
  },
  imageListWrapper: {
    background: theme.palette.common.black,
    width: "inherit",
    height: 450,
    overflowY: "scroll"
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0
  },
  posTester: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    background: "red"
  }
}));

export default useStyles;
