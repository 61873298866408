import { makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  revertPadding: {
    backgroundColor: theme.palette.neutral.main,
    margin: theme.spacing(-5)
  },
  contentSpacer: {
    color: "white",
    padding: theme.spacing(5),
    minHeight: `calc(100vh - ${theme.spacing(11)}px)`
  },
  paragraph: {
    margin: theme.spacing(4, 0)
  }
}));

export default useStyles;
