import palette from "../palette";

export default {
  contained: {
    background: `linear-gradient(45deg, ${palette.primary.main} 30%, ${palette.secondary.main} 90%)`,
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)"
  }
};
