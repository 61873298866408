import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 88,
    justifyContent: "center",
    backgroundColor: "black"
  },
  flexGrow: {
    flexGrow: 1
  },
  avatarIcon: {
    cursor: "pointer"
  },
  menuButton: {
    color: theme.palette.common.white
  },
  linkItem: {
    textDecoration: "none !important",
    color: theme.palette.text.primary
  }
}));

export default useStyles;
