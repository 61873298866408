import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: 256,
    overflowY: "auto"
  },
  navLink: {
    "&.active": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.light,
      fontWeight: theme.typography.fontWeightMedium,
      "&:hover": {
        backgroundColor: theme.palette.secondary.light
      }
    }
  }
}));

export default useStyles;
