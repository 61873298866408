import { translate } from "../utils/i18n";

interface NavItem {
  title: string;
  href: string;
  description?: string;
  id: string;
}

export const navItems = [
  {
    title: translate("titles.home"),
    href: "/",
    id: "home"
  },
  {
    title: translate("titles.about"),
    href: "/a-propos",
    description: translate("pageDescriptions.about"),
    id: "about"
  },
  {
    title: translate("titles.create"),
    href: "/creer",
    description: translate("pageDescriptions.create"),
    id: "create",
    restricted: false
  },
  {
    title: translate("titles.slideshow"),
    href: "/diaporama",
    description: translate("pageDescriptions.slideshow"),
    id: "slideshow"
  },
  {
    title: translate("titles.dashboard"),
    href: "/dashboard",
    description: translate("pageDescriptions.dashboard"),
    id: "dashboard",
    limited: true
  },
  {
    title: translate("titles.upload"),
    href: "/upload",
    description: translate("pageDescriptions.upload"),
    id: "upload",
    limited: true
  },
  {
    title: translate("titles.credits"),
    href: "/credits",
    description: translate("pageDescriptions.credits"),
    id: "credits"
  },
  {
    title: translate("titles.terms&conditions"),
    href: "/termes",
    description: translate("pageDescriptions.terms&conditions"),
    id: "terms&conditions"
  }
];

export const navItemsById: Record<NavItem["id"], NavItem> = {};

function orderNavItemsById() {
  navItems.forEach((item) => {
    navItemsById[item.id] = item;
  });
}
orderNavItemsById();

export default navItems;
