import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  wordingWrapper: {
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 0
  }
}));

export default useStyles;
