import React, { useMemo, forwardRef } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { useAuth } from "../../contexts/AuthContext";
import { useRestrictedAccess } from "../../contexts/RestrictedContext";
import useStyles from "./styles";

function ListItemLink(props) {
  const { primary, to } = props;
  const { search } = useLocation();
  const classes = useStyles();

  const renderLink = useMemo(
    () => forwardRef((itemProps, ref) => <RouterLink exact to={to + search} ref={ref} {...itemProps} />),
    [to, search]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={classes.navLink}>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const Navbar = ({ pages }) => {
  const classes = useStyles();
  // @ts-ignore
  const { currentUser } = useAuth();
  // @ts-ignore
  const { accessGranted } = useRestrictedAccess();

  return (
    <Paper className={classes.root} elevation={1} square>
      <List component="nav">
        {pages.map((page) => {
          if (page.limited && !currentUser) {
            return null;
          }
          if (page.restricted && !accessGranted) {
            return null;
          }

          return <ListItemLink key={page.title} exact primary={page.title} to={page.href} />;
        })}
      </List>
    </Paper>
  );
};

export default Navbar;
