import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { db } from "../utils/firebase";

// @ts-ignore
const RestrictedAccessContext = React.createContext();

export function useRestrictedAccess() {
  return useContext(RestrictedAccessContext);
}

export function RestrictedAccessProvider({ children }) {
  const [accessGranted, setAccessGranted] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const canCreate = getLocationQueryAsObject().getAll("canCreate");

  function setRestrictedAccess(value: boolean) {
    return db.collection("configs").doc("features").set({ create: value });
  }

  function getLocationQueryAsObject() {
    return new URLSearchParams(location.search);
  }

  useEffect(() => {
    setAccessGranted(true);
    setLoading(false);
  }, [accessGranted, canCreate]);

  const value = {
    accessGranted,
    setRestrictedAccess
  };

  return <RestrictedAccessContext.Provider value={value}>{!loading && children}</RestrictedAccessContext.Provider>;
}
