import React from "react";
import { useRecoilState } from "recoil";

import Wording from "../Wording";
import { selectedWordListState } from "../../views/CreateNeoArtwork/subviews/SelectWords";
import useStyles from "./styles";

import { Word } from "../../types";

const WordPositioning = ({ scale, readonly, wording }) => {
  const classes = useStyles();

  const [selectedWordList, setSelectedWordList] = useRecoilState(selectedWordListState);

  function replaceItemAtIndex(arr, index, newValue) {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
  }

  function removeItemAtIndex(arr, index) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }

  const updateWordPositionById = (wordId, position) => {
    const itemIndex = selectedWordList.findIndex((listItem: Word) => listItem.id === wordId);
    const newList = replaceItemAtIndex(selectedWordList, itemIndex, {
      // @ts-ignore
      ...selectedWordList[itemIndex],
      position: { ...position }
    });

    // @ts-ignore
    setSelectedWordList(newList);
  };

  const removeWordFromList = (wordId) => {
    const itemIndex = selectedWordList.findIndex((listItem: Word) => listItem.id === wordId);
    const newList = removeItemAtIndex(selectedWordList, itemIndex);

    // @ts-ignore
    setSelectedWordList(newList);
  };

  const updateWordSizeById = (wordId, { size }) => {
    const itemIndex = selectedWordList.findIndex((listItem: Word) => listItem.id === wordId);
    const newList = replaceItemAtIndex(selectedWordList, itemIndex, {
      // @ts-ignore
      ...selectedWordList[itemIndex],
      size: { ...size }
    });

    // @ts-ignore
    setSelectedWordList(newList);
  };

  wording = readonly ? wording : selectedWordList;

  return (
    <>
      <div className={classes.wordingWrapper}>
        {wording.map((word: Word, index: number) => (
          <Wording
            // eslint-disable-next-line react/no-array-index-key
            key={`${word.id}-${index}`}
            word={word}
            scale={scale}
            readonly={readonly}
            onDragEndHandler={updateWordPositionById}
            onResizeEndHandler={updateWordSizeById}
            onDeleteWordHandler={removeWordFromList}
          />
        ))}
      </div>
    </>
  );
};

export default WordPositioning;
