import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { translate } from "../../utils/i18n";

import NeoArtworkCarousel from "./subviews/NeoArtworkCarousel";
import { db } from "../../utils/firebase";
import useStyles from "./styles";

import { NeoArtwork } from "../../types";

const Slideshow = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [gallery, setGallery] = useState<NeoArtwork[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // @ts-ignore
  useEffect(() => {
    let galleryQuery = db.collection("neoartworks");

    if (id) {
      // @ts-ignore
      galleryQuery = galleryQuery.where("artwork.gallery", "==", id);
    } else {
      // @ts-ignore
      galleryQuery = galleryQuery.orderBy("submittedAt", "desc");
    }

    galleryQuery.onSnapshot((querySnapshot) => {
      const tempGallery = [];
      querySnapshot.forEach((doc) => {
        // @ts-ignore
        tempGallery.push({ ...doc.data() });
      });
      setGallery(tempGallery);
    });

    return galleryQuery;
  }, [id]);

  const handleChangeIndex = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className={classes.revertPadding}>
      <div className={classes.contentSpacer}>
        <NeoArtworkCarousel artworks={gallery} onChangeIndexHandler={handleChangeIndex} currentIndex={currentIndex} />

        <Box className={classes.infoBox}>
          <Typography variant="h5" component="p" gutterBottom className={classes.infoBoxText}>
            {gallery[currentIndex]?.name}
          </Typography>

          <Typography variant="body1" component="p" className={classes.infoBoxText}>
            {`${translate("common.by")} ${gallery[currentIndex]?.author}`}
          </Typography>
          <Typography variant="body2" component="p" className={classes.infoBoxText}>
            {`${translate("view.basedOn")} ${gallery[currentIndex]?.artwork.artist}`}
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default Slideshow;
