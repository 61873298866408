import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start"
    }
  },
  containerTitle: {
    marginBottom: theme.spacing(8)
  },
  cardRoot: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      maxWidth: 310
    }
  },
  cardMedia: {
    height: 220
  },
  cardActions: {
    flexDirection: "row-reverse"
  }
}));

export default useStyles;
