import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";

import { Routes } from "./navigation";
import theme from "./theme";

require("./assets/styles/global.css");

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  // @ts-ignore
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        // @ts-ignore
        ref={notistackRef}
        action={(key) => (
          <IconButton size="small" color="primary" onClick={onClickDismiss(key)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        <CssBaseline />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
