import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import ShareIcon from "@material-ui/icons/Share";

import { db } from "../../utils/firebase";
import { translate } from "../../utils/i18n";
import useStyles from "./styles";

import { NeoArtwork } from "../../types";
import ArtworkWithWords from "../../components/ArtworkWithWords";

const ViewNeoArtwork = () => {
  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const [neoArtwork, setNeoArtwork] = useState<NeoArtwork | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return db
      .collection("neoartworks")
      .doc(id)
      .onSnapshot((snapshot) => {
        // @ts-ignore
        setNeoArtwork(snapshot.data());
      });
  }, [id]);

  const handleOnShareActionClick = () => {
    navigator.clipboard.writeText(`${window.location.origin}${location.pathname}`);
    enqueueSnackbar(translate("view.copiedToClipboard"));
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.origin} />
        <meta property="og:url" content={window.location.pathname} />
        <meta property="og:type" content="picture.art" />
        <meta property="og:title" content={neoArtwork?.name} />
        <meta property="og:image" content={neoArtwork?.artwork.url} />
        <meta
          property="og:description"
          content={`${neoArtwork?.name} ${translate("common.by")} ${neoArtwork?.author} {translate("view.basedOn")} ${
            neoArtwork?.artwork.artist
          }`}
        />
      </Helmet>

      <div className={classes.revertPadding}>
        <div className={classes.contentSpacer}>
          <div className={classes.artworkImageWrapper}>
            <ArtworkWithWords readonly neoArtwork={neoArtwork} />
          </div>

          <Box className={classes.infoBox}>
            <Typography variant="h5" component="p" gutterBottom className={classes.infoBoxText}>
              {neoArtwork?.name}
            </Typography>
            <Typography variant="body1" component="p" className={classes.infoBoxText}>
              {`${translate("common.by")} ${neoArtwork?.author}`}
            </Typography>
            <Typography variant="body2" component="p" className={classes.infoBoxText}>
              {`${translate("view.basedOn")} ${neoArtwork?.artwork.artist}`}
            </Typography>
          </Box>
        </div>
      </div>

      <Fab className={classes.fab} color="primary" onClick={handleOnShareActionClick}>
        <ShareIcon />
      </Fab>
    </>
  );
};

export default ViewNeoArtwork;
