import * as React from "react";
import { withRouter, Route, Redirect, RouterChildContext } from "react-router-dom";

import { useRestrictedAccess } from "../contexts/RestrictedContext";

interface RestrictedRouteProps extends RouterChildContext {
  component: React.ComponentType<any>;
}

const RestrictedRoute = ({ component: Component, ...rest }: RestrictedRouteProps) => {
  // @ts-ignore
  const { accessGranted } = useRestrictedAccess();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        // @ts-ignore
        accessGranted ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default withRouter(RestrictedRoute);
