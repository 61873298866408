export default {
  primary: {
    main: "#fe6b8b"
  },
  secondary: {
    main: "#ff8e53"
  },
  neutral: {
    main: "#141313"
  }
};
