import React from "react";
import SwipeableViews from "react-swipeable-views";
import { virtualize, bindKeyboard } from "react-swipeable-views-utils";

import useStyles from "./styles";

import { Artwork } from "../../types";

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

let localArtworks: Artwork[] = [];

const SlideItem = ({ id, src, alt }: { id: string; src: string; alt: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.artworkImageWrapper}>
      <img className={classes.artworkImage} key={id} src={src} alt={alt} width="350" />
    </div>
  );
};

const slideRenderer = (params) => {
  const { index } = params;

  return (
    <SlideItem
      key={`${localArtworks[index].id}-${index}`}
      id={localArtworks[index].id}
      src={localArtworks[index].artwork.url}
      alt={localArtworks[index].name}
    />
  );
};

const ArtworkCarousel = ({ artworks, currentIndex, onChangeIndexHandler }) => {
  const slideCount = artworks.length;

  localArtworks = artworks;

  if (!artworks.length) {
    return null;
  }

  const handleChangeIndex = (index: number) => {
    if (onChangeIndexHandler) {
      onChangeIndexHandler(index);
    }
  };

  return (
    <VirtualizeSwipeableViews
      onChangeIndex={handleChangeIndex}
      slideCount={slideCount}
      slideRenderer={slideRenderer}
      index={currentIndex}
      overscanSlideBefore={slideCount}
    />
  );
};

export default ArtworkCarousel;
