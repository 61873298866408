import React, { useState } from "react";
import { atom, useSetRecoilState } from "recoil";
import { v4 as uuid } from "uuid";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import ArtworkCarousel from "../../../../components/ArtworkCarousel";
import { translate } from "../../../../utils/i18n";
import { NeoArtwork } from "../../../../types";
import useStyles from "./styles";

export const neoArtworkState = atom({
  key: "neoartwork",
  default: {} as NeoArtwork
});

const SelectArtwork = ({ artworks, onSelectArtworkHandler }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const setNeoArtwork = useSetRecoilState(neoArtworkState);
  const slideCount = artworks.length;

  const handleChangeIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const handleNextIndex = () => {
    handleChangeIndex(currentIndex + 1);
  };

  const handleBackIndex = () => {
    handleChangeIndex(currentIndex - 1);
  };

  const handleOnSelectArtwork = () => {
    setNeoArtwork({
      id: uuid(),
      artwork: artworks[currentIndex],
      url: "",
      author: "",
      description: "",
      shareableUrl: "",
      wording: []
    });

    if (onSelectArtworkHandler) {
      onSelectArtworkHandler(artworks[currentIndex]);
    }
  };

  if (!artworks.length) {
    return null;
  }

  return (
    <>
      <ArtworkCarousel artworks={artworks} onChangeIndexHandler={handleChangeIndex} currentIndex={currentIndex} />

      <Box className={classes.controlsWrapper}>
        <IconButton className={classes.prevButton} onClick={handleBackIndex} disabled={currentIndex === 0}>
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton className={classes.nextButton} onClick={handleNextIndex} disabled={currentIndex === slideCount - 1}>
          <KeyboardArrowRight />
        </IconButton>
      </Box>

      <Fab className={classes.fab} color="primary" variant="extended" onClick={handleOnSelectArtwork}>
        {translate("create.selectArtwork")}
      </Fab>
    </>
  );
};

export default SelectArtwork;
