import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  textfield: {
    marginBottom: theme.spacing(2)
  }
}));

export default useStyles;
