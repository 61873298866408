import React from "react";
import { useRecoilState } from "recoil";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import DraggableBox from "../DraggableBox";
import ResizableBox from "../ResizableBox";
import { wordListIsEditableState } from "../../views/CreateNeoArtwork/subviews/SelectWords";
import useStyles from "./styles";

const Wording = ({ scale, word, readonly, onDragEndHandler, onResizeEndHandler, onDeleteWordHandler }) => {
  const classes = useStyles();
  const [wordListIsEditable] = useRecoilState(wordListIsEditableState);

  return (
    <DraggableBox
      scale={scale}
      disabled={!wordListIsEditable || readonly}
      position={{ top: word.position.top, left: word.position.left }}
      onStop={(data) => {
        onDragEndHandler(word.id, data);
      }}
    >
      <div
        style={{
          position: "absolute"
        }}
      >
        {wordListIsEditable && !readonly && (
          <div className={classes.deleteButtonWrapper}>
            <IconButton
              size="medium"
              className={classes.deleteButton}
              onClickCapture={(e) => {
                e.preventDefault();
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
                onDeleteWordHandler(word.id);
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        )}

        <ResizableBox
          disabled
          onResize={(size) => onResizeEndHandler(word.id, size)}
          position={{ top: word.position.top, left: word.position.left }}
          size={{ width: word.size.width * 2, height: word.size.height * 2 }}
        >
          <div
            style={{
              position: "absolute",
              display: "flex",
              width: word.size.width * 2, // temporary fix to bypass global scaling
              height: word.size.height * 2 // temporary fix to bypass global scaling
            }}
          >
            <div
              style={{
                flex: 1,
                backgroundImage: `url('${word?.url}')`,
                backgroundSize: "cover"
              }}
            />
          </div>
        </ResizableBox>
      </div>
    </DraggableBox>
  );
};

export default Wording;
