import React, { ChangeEvent, useState } from "react";
import { v4 as uuid } from "uuid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import FileDropzone from "../FileDropzone";
import { db, storage } from "../../utils/firebase";
import { translate } from "../../utils/i18n";
import useStyles from "./styles";

import { WordUploadFormState } from "./index.interface";

const WordUploadForm = () => {
  const classes = useStyles();
  const formDataDefaultValues = {
    value: ""
  };
  const [formData, setFormData] = useState<WordUploadFormState>(formDataDefaultValues);
  const [word, setWord] = useState<File | null>(null);
  const [encodedWord, setEncodedWord] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const handleChange = (prop: keyof WordUploadFormState) => (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormData((formState) => ({
      ...formState,
      [prop]: event.target.value
    }));
  };

  const handleResetFormData = () => {
    setFormData(formDataDefaultValues);
    setEncodedWord(null);
  };

  const onDropArtworkHandler = (file) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      const binaryStr = reader.result;
      // @ts-ignore
      setEncodedWord(`data:image/jpeg;base64,${btoa(binaryStr)}`);
    };
    setWord(file);
  };

  const handleSubmitArtwork = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (!word) {
      return;
    }

    const storageRef = storage.ref();
    const fileRef = storageRef.child(word.name);
    const uploadTask = fileRef.put(word);

    uploadTask.on(
      "state_changed",
      () => {},
      () => {
        setIsSnackbarOpen(true);
        setIsLoading(false);
      },
      () => {
        fileRef.getDownloadURL().then((url) => {
          const image = new Image();
          // @ts-ignore
          image.src = encodedWord;

          image.onload = () => {
            db.collection("words").doc(formData.value).set({
              id: uuid(),
              name: formData.value,
              url,
              width: image.width,
              height: image.height
            });
          };

          handleResetFormData();
          setIsLoading(false);
        });
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmitArtwork}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={translate("common.word")}
          value={formData.value}
          name="value"
          onChange={handleChange("value")}
        />

        <div className={classes.fileUploadWrapper}>
          {encodedWord && <img className={classes.artworkPreview} src={encodedWord} alt={formData.value || ""} />}
          <div className={classes.fileUploadContainer}>
            <FileDropzone onFilesDropHandler={onDropArtworkHandler} />
          </div>
        </div>

        <Button
          fullWidth
          type="submit"
          size="large"
          variant="contained"
          className={classes.submit}
          startIcon={isLoading && <CircularProgress color="inherit" size={24} />}
          disabled={isLoading}
        >
          {translate("common.save")}
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={translate("error.unknown")}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default WordUploadForm;
