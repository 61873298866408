import React, { ReactNode } from "react";

import useStyles from "./styles";

const ImageMasonryListItem = React.forwardRef((inProps: { children: ReactNode; onClick?: () => void }, ref) => {
  const { children, ...other } = inProps;
  const classes = useStyles();

  return (
    <li
      className={classes.listItem}
      // @ts-ignore
      ref={ref}
      {...other}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </li>
  );
});

export default ImageMasonryListItem;
