import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { db } from "../../utils/firebase";
import groupBy from "../../utils/groupBy";
import { translate } from "../../utils/i18n";
import { navItemsById } from "../../navigation/navigationConfig";
import useStyles from "./styles";

function NavButton(props) {
  const { color, variant, size, to, children } = props;
  const renderLink = useMemo(
    () => forwardRef((itemProps, ref) => <RouterLink exact to={to} ref={ref} {...itemProps} />),
    [to]
  );

  return (
    <Button component={renderLink} color={color} variant={variant} size={size}>
      {children}
    </Button>
  );
}

const Dashboard = () => {
  const classes = useStyles();
  const [galleries, setGalleries] = useState<
    {
      url: string;
      id?: string;
      metadata: {
        timeCreated: string;
        name: string;
      };
    }[]
  >([]);

  useEffect(() => {
    const tempGalleries = [];
    return db.collection("artworks").onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        // @ts-ignore
        tempGalleries.push({ ...doc.data() });
      });

      setGalleries(groupBy(tempGalleries, (g) => g.gallery));
    });
  }, []);

  return (
    <>
      <Grid container item xs={12} justify="space-between" alignItems="flex-start">
        <Typography variant="h4" component="p" className={classes.containerTitle}>
          {translate("titles.dashboard")}
        </Typography>
      </Grid>

      <Grid
        container
        spacing={3}
        className={classes.container}
        direction="row"
        justify="space-evenly"
        alignItems="flex-start"
      >
        {Object.keys(galleries).map((galleryIndex) => (
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            component={Card}
            key={`${galleryIndex}`}
            className={classes.cardRoot}
            variant="outlined"
          >
            <CardActionArea>
              <CardMedia
                className={classes.cardMedia}
                image={galleries[galleryIndex][0].artwork.url}
                title={galleryIndex}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="p" noWrap>
                  {galleryIndex}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`${galleries[galleryIndex].length} ${translate("common.originalArtworks")}`}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
              <NavButton to={`${navItemsById.slideshow.href}/${galleryIndex}`} size="small" variant="outlined">
                {translate("titles.slideshow")}
              </NavButton>
            </CardActions>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Dashboard;
