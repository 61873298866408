import React, { ChangeEvent, useState } from "react";
import { v4 as uuid } from "uuid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import FileDropzone from "../FileDropzone";
import { db, storage } from "../../utils/firebase";
import { translate } from "../../utils/i18n";
import useStyles from "./styles";

import { GalleryUploadFormState } from "./index.interface";

const GalleryUploadForm = () => {
  const classes = useStyles();
  const formDataDefaultValues = {
    name: "",
    artist: "",
    gallery: ""
  };
  const [formData, setFormData] = useState<GalleryUploadFormState>(formDataDefaultValues);
  const [artwork, setArtwork] = useState<File | null>(null);
  const [encodedArtwork, setEncodedArtwork] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const handleChange = (prop: keyof GalleryUploadFormState) => (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormData((formState) => ({
      ...formState,
      [prop]: event.target.value
    }));
  };

  const handleResetFormData = () => {
    setFormData(formDataDefaultValues);
    setEncodedArtwork(null);
  };

  const onDropArtworkHandler = (file) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      const binaryStr = reader.result;
      // @ts-ignore
      setEncodedArtwork(`data:image/jpeg;base64,${btoa(binaryStr)}`);
    };
    setArtwork(file);
  };

  const handleSubmitArtwork = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (!artwork) {
      return;
    }

    const storageRef = storage.ref();
    const fileRef = storageRef.child(artwork.name);
    const uploadTask = fileRef.put(artwork);

    uploadTask.on(
      "state_changed",
      () => {},
      () => {
        setIsSnackbarOpen(true);
        setIsLoading(false);
      },
      () => {
        fileRef.getDownloadURL().then((url) => {
          db.collection("artworks")
            .doc(formData.name)
            .set({
              id: uuid(),
              name: formData.name,
              artist: formData.artist,
              gallery: formData.gallery,
              artwork: {
                url,
                name: artwork.name
              }
            });

          handleResetFormData();
          setIsLoading(false);
        });
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmitArtwork}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={translate("common.artworkName")}
          value={formData.name}
          name="name"
          onChange={handleChange("name")}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={translate("common.artistName")}
          value={formData.artist}
          name="artist"
          onChange={handleChange("artist")}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={translate("common.gallery")}
          value={formData.gallery}
          name="gallery"
          onChange={handleChange("gallery")}
        />

        <div className={classes.fileUploadWrapper}>
          {encodedArtwork && <img className={classes.artworkPreview} src={encodedArtwork} alt={formData.name || ""} />}
          <div className={classes.fileUploadContainer}>
            <FileDropzone onFilesDropHandler={onDropArtworkHandler} />
          </div>
        </div>

        <Button
          fullWidth
          type="submit"
          size="large"
          variant="contained"
          className={classes.submit}
          startIcon={isLoading && <CircularProgress color="inherit" size={24} />}
          disabled={isLoading}
        >
          {translate("common.save")}
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={translate("error.unknown")}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default GalleryUploadForm;
