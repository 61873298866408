import React, { ChangeEvent, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import GalleryUploadForm from "../../components/GalleryUploadForm";
import WordUploadForm from "../../components/WordUploadForm";
import { translate } from "../../utils/i18n";
import useStyles from "./styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

const Upload = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box className={classes.revertPadding}>
      <AppBar position="static" color="transparent">
        <Tabs value={tabValue} indicatorColor="secondary" onChange={handleChange}>
          <Tab label={translate("common.artwork")} />
          <Tab label={translate("common.word")} />
        </Tabs>
      </AppBar>
      <Box className={classes.tabpanelWrapper}>
        <TabPanel value={tabValue} index={0} className={classes.tabpanel}>
          <GalleryUploadForm />
        </TabPanel>
        <TabPanel value={tabValue} index={1} className={classes.tabpanel}>
          <WordUploadForm />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Upload;
