import React from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import { useAuth } from "../../contexts/AuthContext";
import { translate } from "../../utils/i18n";
import { navigationConfig } from "../../navigation";
import useStyles from "./styles";

import { HeaderProps } from "./index.interface";

const Header = (props: HeaderProps) => {
  const { toggleDrawerHandler } = props;
  const classes = useStyles();
  // @ts-ignore
  const { logout, currentUser } = useAuth();
  const history = useHistory();
  const matchesUpMD = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnLogoutClick = async (e) => {
    e.preventDefault();
    handleClose();

    // @ts-ignore
    await logout();

    history.replace("/");
  };

  return (
    <AppBar className={classes.root} position={matchesUpMD ? "static" : "fixed"}>
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={toggleDrawerHandler && toggleDrawerHandler}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.flexGrow} />

        {currentUser && (
          <>
            <IconButton ref={anchorRef} onClick={handleMenu}>
              <Avatar className={classes.avatarIcon}>
                <AccountCircle />
              </Avatar>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={isOpen}
              keepMounted
            >
              <MenuItem>
                <RouterLink
                  to={navigationConfig.find((item) => item.id === "dashboard")?.href || "/"}
                  className={classes.linkItem}
                >
                  {navigationConfig.find((item) => item.id === "dashboard")?.title || ""}
                </RouterLink>
              </MenuItem>
              <MenuItem>
                <RouterLink
                  to={navigationConfig.find((item) => item.id === "upload")?.href || "/"}
                  className={classes.linkItem}
                >
                  {navigationConfig.find((item) => item.id === "upload")?.title || ""}
                </RouterLink>
              </MenuItem>
              <MenuItem onClick={handleOnLogoutClick}>{translate("common.logout")}</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
